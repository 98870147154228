// Import Swiper and modules
import 'regenerator-runtime/runtime';

/* Libraries */
// import {
// 	gsap
// } from "../../config/node_modules/gsap";

//
// async function loadSwiper() {
// }
// loadSwiper();
console.info('ja doet ie');
let assets = [
	{
		class: '.filter__result',
		functionNames: ['filter'],
		path: 'modules/filter/filter'
	},
	{
		class: '.selector',
		functionNames: ['allSelectors'],
		path: 'modules/selector'
	}
]
// let assets = [
// 	{
// 		class: '.swiper-wrapper',
// 		functionNames: ['swiper'],
// 		path: 'modules/swiper'
// 	}, {
// 		class: '.filter__result',
// 		functionNames: ['filter'],
// 		path: 'modules/filter/filter'
// 	}, {
// 		class: '.contact_form',
// 		functionNames: ['form'],
// 		path: 'modules/form/form'
// 	}, {
// 		class: '.form_steps',
// 		functionNames: ['initFormSteps'],
// 		path: 'modules/form/form_steps'
// 	}, {
// 		class: '.tabs_element',
// 		functionNames: ['tabs'],
// 		path: 'tabs'
// 	}, {
// 		class: '.accordion',
// 		functionNames: ['accordion'],
// 		path: 'modules/accordion'
// 	}, {
// 		class: '.video',
// 		functionNames: ['video'],
// 		path: 'modules/video'
// 	}, {
// 		class: 'audio',
// 		functionNames: ['audio'],
// 		path: 'modules/audio'
// 	}, {
// 		class: '.course_select',
// 		functionNames: ['course_select'],
// 		path: 'course_select'
// 	}, {
// 		class: '.lightbox',
// 		functionNames: ['lightbox'],
// 		path: 'modules/lightbox'
// 	}, {
// 		class: '.selector',
// 		functionNames: ['allSelectors'],
// 		path: 'modules/selector'
// 	}, {
// 		class: '.map',
// 		functionNames: ['map'],
// 		val: ['.news_section__inner'],
// 		path: 'modules/map'
// 	}, {
// 		class: '.popup',
// 		functionNames: ['popup'],
// 		path: 'popup'
// 	}
// ]

assets.forEach(asset => {
	async function loadAssets() {
		if (document.querySelector(asset.class)) {
			let imp = await import('./scripts/' + asset.path);
			asset.functionNames.forEach((name, index) => {
				if (asset.val && asset.val[index]) {
					imp[name](asset.val[index]);
				} else {
					imp[name]();
				}
			})
		}
	}
	loadAssets()
})
/* Modules */
// async function loadAssetsIframe() {
// 	if (inIframe()) {
// 		let loadUpdateCsss = await import('./scripts/functions/updatecss');
// 		loadUpdateCsss.updateCss();
// 	}
// }
// loadAssetsIframe()

// import {
// 	nav
// } from './scripts/modules/nav'
// nav();


/* Functions */
// import {
// 	lazyload
// } from './scripts/functions/lazyload'
// lazyload(document)

// import {
// 	scrollTo
// } from './scripts/functions/scrollTo';
// scrollTo()

// function inIframe() {
// 	try {
// 		return window.self !== window.top;
// 	} catch (e) {
// 		return true;
// 	}
// }
/* Styles */

// import './styles/style.scss';


// import 'regenerator-runtime/runtime';

// /* Libraries */
// // import {
// // 	gsap
// // } from "../../config/node_modules/gsap";

// async function loadSwiper() {
// 	if (document.querySelector('.swiper-wrapper')) {
// 		let swiper = await import('./scripts/modules/swiper');
// 		swiper.swiper();
// 	}
// }
// loadSwiper();

// /* Modules */
// async function loadFilter() {
// 	if (document.querySelector('.filter__result')) {
// 		let filter = await import('./scripts/modules/filter/filter');
// 		filter.filter();
// 	}
// }
// loadFilter();



// async function loadFormSteps() {
// 	if (document.querySelector('.form_steps')) {
// 		let formSteps = await import('./scripts/modules/form/form_steps');
// 		formSteps.initFormSteps();
// 	}
// }
// loadFormSteps();


// async function loadAccordion() {
// 	if (document.querySelector('.accordion')) {
// 		let accordion = await import('./scripts/modules/accordion');
// 		accordion.accordion();
// 	}
// }
// loadAccordion();

// async function loadForm() {
// 	if (document.querySelector('.contact_form')) {
// 		let form = await import('./scripts/modules/form/form');
// 		form.form();
// 	}
// }
// loadForm();

// import {
// 	nav
// } from './scripts/modules/nav'
// nav();

// async function loadVideo() {
// 	if (document.querySelector('.video')) {
// 		let form = await import('./scripts/modules/video');
// 		form.video();
// 	}
// }
// loadVideo();

// async function loadLightbox() {
// 	if (document.querySelector('.lightbox')) {
// 		let lightbox = await import('./scripts/modules/lightbox');
// 		lightbox.lightbox();
// 	}
// }
// async function loadSelector() {
// 	if (document.querySelector('.selector')) {
// 		let selector = await import('./scripts/modules/selector');
// 		selector.allSelectors();
// 	}
// }

// loadSelector();
// loadLightbox();

// async function loadMap() {
// 	if (document.querySelector('.map')) {
// 		let map = await import('./scripts/modules/map');
// 		map.map(document.querySelector('.news_section__inner'));
// 	}
// }
// loadMap()

// /* Functions */
// import {
// 	lazyload
// } from './scripts/functions/lazyload'
// lazyload(document)

// import {
// 	scrollTo
// } from './scripts/functions/scrollTo';
// scrollTo(document.getElementsByTagName('a'));

// async function loadUpdateCss() {
// 	function inIframe() {
// 		try {
// 			return window.self !== window.top;
// 		} catch (e) {
// 			return true;
// 		}
// 	}

// 	if (inIframe()) {
// 		let loadUpdateCsss = await import('./scripts/functions/updatecss');
// 		loadUpdateCsss.updateCss();
// 	}
// }
// loadUpdateCss()

// /* Styles */

// import './styles/style.scss';